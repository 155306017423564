import React from "react"
import styles from './Footer.module.scss';
import mynzLogo from '../../images/2mynz-logo-white.png';

const Footer = () => {
	return(
		<footer className={styles.footer}>
			<img src={mynzLogo} alt='' /> 
			<p>&copy; {new Date().getFullYear()} 2MYNZ</p>
		</footer>
	)
}


export default Footer
