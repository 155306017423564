/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import "../styles/main.scss"


import Header from "./Header"
import Footer from "./Footer"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

	return (
		<Fragment>
			<div className='app__content'>
				<Header siteTitle={data.site.siteMetadata?.title || `Title`} />
				<main>{children}</main>
			</div>
			<Footer />
		</Fragment>

	)
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
