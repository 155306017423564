import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import styles from './Header.module.scss';
import { X, Menu } from 'react-feather'
import mynzLogo from '../../images/2mynz-logo.png';
import cx from 'classnames';
import { Location } from '@reach/router';

const Header = ({ siteTitle }) => {

	const [menuOpen, setMenuOpen] = useState(false);

	const isActive = ({ isCurrent }) => {
		return isCurrent ? { className: `${styles.mastheadNavLink} ${styles.mastheadNavLinkActive}` } : { className: styles.mastheadNavLink }
	}


	useEffect(() => {
		if (!menuOpen) {
			document.body.classList.remove('preventScroll');
		}
	}, [menuOpen])

	const toggleMenu = () => {
		if (!menuOpen) {
			setMenuOpen(true);
			setTimeout(() => document.body.classList.add('preventScroll'), 500)
		} else {
			setMenuOpen(false);
			setTimeout(() => document.body.classList.remove('preventScroll'), 500)
		}
	}


	return (
		<Location>
			{({ location }) => (
				<nav className={styles.masthead}>
					<div className={styles.mastheadBrand}>
						<Link to="/" className={cx({ [styles.mastheadBrandIndex]: location.pathname === '/' })}><img width='120' src={mynzLogo} alt={`${siteTitle} logo`} /> </Link>
					</div>
					<div className={styles.mastheadToggle}>
						<Menu onClick={() => toggleMenu()} />
					</div>
					<div className={cx(styles.mastheadNav, { [styles.mastheadNavVisible]: menuOpen })}>
						<span className={styles.mastheadCloseNav} onClick={() => toggleMenu()}><X size="28" /></span>
						<ul>
							<li><Link to='/' getProps={isActive}>Home</Link></li>
							<li><Link to='/about' getProps={isActive}>About 2mynz</Link></li>
							{/* <li><Link to='/cooptech' getProps={isActive}>Cooptech</Link></li> */}
							<li><Link to='/contact' getProps={isActive}>Contact</Link></li>
						</ul>
					</div>
				</nav>
			)}
		</Location>
	)
}


Header.propTypes = {
	siteTitle: PropTypes.string,
}

Header.defaultProps = {
	siteTitle: `2MYNZ`,
}

export default Header
